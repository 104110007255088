import { useEffect, useRef, useState } from 'react'
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Divider, Empty, Image, Popover, Row, Spin, Table, TableColumnsType, Tag, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MAPS, red, blue, yellow, green, grey, CHARGERS, FUEL, POWER_GENERATION, faults, alarms, CAMERAS } from '../../utils/constants';
import Maps from '../maps/Maps';
import './General.css'
import charging from '../../assets/Dashboard/ChargerCharging.svg';
import available from '../../assets/Dashboard/ChargerAvailable.svg';
import fault from '../../assets/Dashboard/ChargerFaulted.svg';
import staticCharger from '../../assets/Dashboard/ChargerStatic.svg';
import _ from 'lodash';
import mapsLogo from '../../assets/general/location-01-solid-rounded 3.png';
import TelecomGreen from '../../assets/general/TelecomGreen.svg';
import TelecomRed from '../../assets/general/TelecomRed.svg';
import { SummaryData } from '../../redux/types/chargerTypes';
import { detailedGeneratorData, LiveDataItems } from '../../redux/types/powerTypes';
import FuelGreen from '../../assets/general/Fuel.svg';
import Weather from '../../assets/general/Weather.svg';
import StarLinkGreen from '../../assets/sideNav/selected/StarLink.svg';
import StarLinkGreen2 from '../../assets/sideNav/selected/StarLink2.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import { TableInterface } from '../../redux/types/dashboardTypes';
import { setSelectedGeneratorId, setSelectedSourceId } from '../../redux/slices/powerSlice';
import { VideoCameraOutlined } from '@ant-design/icons';
import { fetchWeatherData } from '../../redux/services/customerService';
dayjs.extend(utc);
dayjs.extend(timezone);



const General = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { customer } = useParams();
    const { selectedCustomerName, customerSites, customerSitesLoading, selectedSite, generalDetails, generalDetailsLoading, permittedCustomers, selectedTimezone, selectedTimezoneAbb, weatherInfo, weatherInfoLoading, weatherInfoError } = useSelector((state: RootState) => state.userInfo);
    useSelector((state: RootState) => state.power);
    const { detailedChargerDataForSite, loadingDetailedChargerDataForSite, detailedGeneratorDataForSite, loadingGenDetailsForSite } = useSelector((state: RootState) => state.dashboard);
    const { fetchLiveDataAcrossSitesSourceIdsLoading } = useSelector((state: RootState) => state.chargers);
    const presentComponents = customerSites.find((site) => site.name === selectedSite)?.preferences?.present_components;

    const siteLocation: any = customerSites.find(
        (site) => site.name === selectedSite,
    )?.location;
    const [selectedSiteLocationUpdated, setSelectedSiteLocationUpdated] = useState<string>("");

    useEffect(() => {
        if (siteLocation?.latitude > 0) {
            setSelectedSiteLocationUpdated(siteLocation?.last_update_at);
        }
    }, [siteLocation]);

    const responsiveCol = { xxl: 14, xl: 14, lg: 24, md: 24, sm: 24, xs: 24 };

    const responsiveColFIrst = { xxl: 10, xl: 10, lg: 24, md: 24, sm: 24, xs: 24 };

    const responsiveColTwo = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };

    const responseiveNew2 = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };


    function transformString(input: string) {
        const regex = /[^_]+_(.+)/;
        const transformedString = input.replace(regex, (_, suffix) => {
            const replacedUnderscores = suffix.replace(/_/g, ' ');
            const withSpacesBeforeCaps = replacedUnderscores.replace(/([A-z0-9])([A-Z])/g, '$1 $2');
            return withSpacesBeforeCaps.replace(/\bCharger\b/g, 'Charger(s)');
        });

        return transformedString;
    }

    const { Truck_Siteid, Truck_ImageUrl, ...filteredGeneralDetails } = generalDetails;

    const data = Object.entries(filteredGeneralDetails)
        .map(([setting, value]) => ({
            key: setting, // Use the setting as the key
            Setting: transformString(setting),
            Value: value !== undefined && value !== null && value !== "" ? value : "NIL", // Replace empty values with "NIL"
        }));


    const columns = [
        {
            title: 'SETTING',
            dataIndex: 'Setting',
            key: 'Setting',
        },
        {
            title: 'VALUE',
            dataIndex: 'Value',
            key: 'Value',
        },
    ];

    const handleMapClick = () => {
        presentComponents?.includes(MAPS) ? navigate(`/${selectedCustomerName}/${selectedSite}/${MAPS}`) : console.log('No MAPS');
    }

    const [filteredFuel, setFilteredFuel] = useState<any>([]);
    const [fuelSum, setFuelSum] = useState(0);

    useEffect(() => {
        // First condition: when there is data in detailedGeneratorDataForSite
        if (detailedGeneratorDataForSite && detailedGeneratorDataForSite.length > 0) {
            const filteredData: any = [];

            detailedGeneratorDataForSite.forEach(site => {
                site.equipment_data.forEach(equipment => {
                    equipment.data.forEach(entry => {
                        const fuelLevelRegex = /Fuel Level(\s*\(.*\))?/i;

                        // Check if the customer is 'bchydro' and the source ID matches
                        if (selectedCustomerName === 'bchydro' && fuelLevelRegex.test(entry.value_name) && entry.source_id === 'BCH-Fuel') {
                            filteredData.push(entry);
                        }
                        // If not 'bchydro', include entries with relevant value names
                        else if (selectedCustomerName !== 'bchydro' && fuelLevelRegex.test(entry.value_name)) {
                            filteredData.push(entry);
                        }
                    });
                });
            });

            // Remove duplicates based on unique keys (equipment_id, value_name)
            const uniqueFilteredData = Array.from(
                new Map(filteredData.map((item: any) => [`${item.equipment_id}-${item.value_name}`, item])).values()
            );

            // Sum up the values (filtering out negatives)
            const sum = uniqueFilteredData.reduce((accumulator: number, currentValue: any) => {
                return accumulator + (currentValue.value < 0 ? 0 : currentValue.value);
            }, 0);

            // Set the sum and filtered data to state
            setFuelSum(sum);
            setFilteredFuel(uniqueFilteredData);
        }
    }, [detailedGeneratorDataForSite, selectedCustomerName]);

    const [filteredData, setFilteredData] = useState<any>([]);

    const [generatorData, setGeneratorData] = useState<any>([]);


    useEffect(() => {
        if (customerSites.length > 0) {
            const newData = customerSites.filter(item => item.name === selectedSite).map((site) => ({
                ...site,
                generatorStatus: detailedGeneratorDataForSite?.filter(item => item.site_id === site.id) || [],
                chargerStatus: detailedChargerDataForSite?.connector?.filter(item => item.siteId === site.id) || [],
                chargerList: detailedChargerDataForSite?.all_connected_chargers?.filter(item => item.site_id === site.id) || [],
            }));

            const genEachData = newData[0]?.generatorStatus.map(item => item.equipment_data).flat() || [];

            setFilteredData(newData);

            const filteredGenData = genEachData.filter(
                (item) =>
                    item?.data?.[0]?.source_id !== 'BCH-Fuel' &&
                    !['EV Charger', 'EV_Charger'].includes(item?.data?.[0]?.equipment_name)
            );

            setGeneratorData(filteredGenData);
        } else {
            setGeneratorData([]);
        }
    }, [detailedGeneratorDataForSite, detailedChargerDataForSite, customerSites]);

    interface ColorCircleProps {
        color: string;  // The color for the circle background
        value: number;  // The number or text to display in the center
    }

    const ColorCircle: React.FC<ColorCircleProps> = ({ color, value }) => {
        return (
            <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                {/* Circle with dynamic color */}
                <circle cx="17.5" cy="17.5" r="17.5" fill={color} />
                {/* Number/Text in the center */}
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="central"
                    textAnchor="middle"
                    pointerEvents={'all'}
                    fill="white"
                    fontSize="16"
                    fontWeight={400}
                    fontFamily="Arial, sans-serif"
                    dy="0.05em"
                    dx={value > 99 ? '-0.1em' : ''}
                >
                    {value === -123 ? '' : value < 0 ? 'N/A' : value}
                </text>
            </svg>
        );
    };

    const columnCharger: TableColumnsType<TableInterface> = [
        {
            title: '',
            key: 'chargerName',
            dataIndex: 'chargerName',
        },
        {
            title: 'CHARGERS',
            key: 'status',
            dataIndex: 'status',
            render: (status: any, record: any) => (
                <Popover content={<div>{status}</div>}>
                    <Link to={`/${selectedCustomerName}/${selectedSite}/${CHARGERS}/${record.key}`}>
                        <ColorCircle
                            color={
                                status === 'Available'
                                    ? green
                                    : ['Charging', 'Preparing'].includes(status)
                                        ? yellow
                                        : ['Faulted', 'Suspended EV'].includes(status)
                                            ? red
                                            : grey
                            }
                            value={-123} // Value can be adjusted as needed for the ColorCircle component
                        />
                    </Link>
                </Popover>
            ),
        },
    ];


    const dataSourceCharger: TableInterface[] = filteredData.flatMap((record: any) =>
        record.chargerList.map((charger: any) => {
            const matchingStatus = record.chargerStatus.find(
                (status: any) => status.charger === charger.id
            );

            const connectorStatus =
                matchingStatus?.connectors?.[0]?.status || 'No Status available';
            const staticCharger = charger.api_source === 'static';

            return {
                key: charger.id,
                chargerName: charger.charger_title,
                status: staticCharger ? 'Static Charger' : connectorStatus,
            };
        })
    );

    const columnsGen: TableColumnsType<LiveDataItems> = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record) => <span>{record.data[0].equipment_name}</span>,
        },
        {
            title: 'GENERATOR',
            key: '3',
            dataIndex: 'generators',
            render: (text: any, record) => {
                const notliveData = record?.LiveStatus !== 'live';
                const runningStatus = record?.data?.find((item: any) => item.value_name === 'Running')?.value === 1;

                const faultStatuses = record?.data
                    ?.filter(item => faults.includes(item.value_name) && (item.value === 1))
                    ?.map(item => item.value_name)
                    || [];

                const alarmStatuses = record?.data
                    ?.filter((item) => alarms.includes(item.value_name) && (item.value === 1))
                    ?.map((item) => item.value_name)
                    || [];

                const faultStatusesNegative = record?.data?.some(item =>
                    item?.value_name && faults.includes(item.value_name) && item.value === -99
                );

                const engineHourCheck = record && record?.data && record?.data?.filter(item => item?.value_name === 'Engine Hours') && record?.data?.filter(item => item?.value_name === 'Engine Hours')[0]?.value < 0 || false;

                const lastUpdated = record?.data?.[0]?.value_datetime ? selectedTimezone === 'UTC' ? dayjs.utc(record?.data?.[0]?.value_datetime).format('YYYY-MM-DD HH:mm:ss') : dayjs(record?.data?.[0]?.value_datetime).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss') : null;

                const color = notliveData || engineHourCheck || faultStatusesNegative
                    ? grey
                    : runningStatus
                        ? blue
                        : faultStatuses.length > 0
                            ? red
                            : alarmStatuses.filter((alarm) => !['Common Alarm', 'Warning Alarm'].includes(alarm)).length > 0
                                ? yellow
                                : green;
                const tableData = [
                    {
                        key: record.data[0].equipment_name,
                        status: notliveData || faultStatusesNegative || engineHourCheck ? 'Unknown/Offline' : runningStatus ? 'Running' : faultStatuses.length > 0 ? 'Fault' : alarmStatuses.filter((alarm) => !['Common Alarm', 'Warning Alarm'].includes(alarm)).length > 0 ? 'Alarm' : 'Able to run',
                        faults: faultStatuses.length === 0 ? 'None' : faultStatuses.map((fault) => fault).join(', '),
                        alarms: alarmStatuses.length === 0 ? 'None' : alarmStatuses.map((alarm) => alarm).join(', '),
                        lastUpdated: lastUpdated
                    },
                ];

                const columns = [
                    { title: 'Status', dataIndex: 'status', key: 'status' },
                    { title: 'Faults', dataIndex: 'faults', key: 'faults' },
                    { title: 'Alarms', dataIndex: 'alarms', key: 'alarms' },
                    { title: 'Last updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                ];

                return (
                    <Popover
                        key={record.data[0].equipment_name}
                        content={
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                                size="small"
                                bordered
                            />
                        }
                        color="white"
                        styles={{ body: { padding: 0 } }}
                    >
                        <Link to={`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${record?.equipment_id}`}>
                            <ColorCircle color={color} value={-123} />
                        </Link>
                    </Popover>)
            }
        },
        {
            title: 'BATTERY',
            dataIndex: 'battery',
            render: (text: any, record) => {
                const hasBatteryData = record.data.some((record) => record.value_name === 'Battery Voltage')


                if (!hasBatteryData) {
                    return <ColorCircle color={grey} value={-1} />

                }

                const batteryData = record.data.find(
                    (item) => item.value_name === 'Battery Voltage'
                );
                const generatorBattery = batteryData?.value ?? 'N/A';
                const genName = record.data[0]?.equipment_name;
                const lastUpdated = record.data[0]?.value_datetime ? selectedTimezone === 'UTC' ? dayjs.utc(record.data[0]?.value_datetime).format('YYYY-MM-DD HH:mm:ss') : dayjs(record.data[0]?.value_datetime).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss') : '';
                const notliveData = record?.LiveStatus !== 'live';
                if (
                    ['EV Charger', 'EV_Charger'].includes(genName) ||
                    record.data[0]?.source_id === 'BCH-Fuel'
                ) {
                    return null;
                }

                const tableData = [
                    {
                        key: `${record.data[0].equipment_name}`,
                        name: genName || 'Unknown',
                        batteryVoltage: generatorBattery,
                        lastUpdated: lastUpdated,
                        status:
                            generatorBattery === 'N/A' ? 'Unknown/Offline' : notliveData || generatorBattery < 0 ? 'Unknown/Offline' :
                                generatorBattery === 0
                                    ? 'No Voltage'
                                    : generatorBattery < 10
                                        ? 'Low'
                                        : generatorBattery < 12
                                            ? 'Moderate'
                                            : 'Good',
                    },
                ];

                const columns = [
                    { title: 'Battery Voltage', dataIndex: 'batteryVoltage', key: 'batteryVoltage' },
                    { title: 'Status', dataIndex: 'status', key: 'status' },
                    { title: 'Last Updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                ];

                const fillColor =
                    generatorBattery === 'N/A' ? grey : notliveData || generatorBattery < 0 ? grey :
                        generatorBattery === 0
                            ? grey
                            : generatorBattery < 10
                                ? red
                                : generatorBattery < 12
                                    ? '#F19D00'
                                    : green;

                const key = `${record.data[0].equipment_name}`;

                const genId = record.data[0]?.equipment_id
                return (<Popover
                    content={
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            size="small"
                            bordered
                        />
                    }
                    key={key}
                    color="white"
                    styles={{ body: { padding: 0 } }}
                >
                    <Link
                        to={`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${genId}`}
                        onClick={() => dispatch(setSelectedGeneratorId(genId), dispatch(setSelectedSourceId(record.data[0]?.source_id)))}
                        style={{ marginRight: 5 }}
                    >
                        <ColorCircle
                            value={_.round(generatorBattery === 'N/A' ? -1 : generatorBattery)}
                            color={fillColor}
                        />
                    </Link>
                </Popover>)
            }
        }
    ];

    const dataSource = generatorData;

    const { Title } = Typography;

    const status = customerSites.filter(item => item.name === selectedSite)?.[0]?.location && customerSites.filter(item => item.name === selectedSite)[0]?.location?.status === 1;

    const [isFahrenheit, setIsFahrenheit] = useState(true);

    // Conversion functions
    const kelvinToFahrenheit = (kelvin: any) => (kelvin - 273.15) * 9 / 5 + 32;
    const kelvinToCelsius = (kelvin: any) => kelvin - 273.15;

    // Current temperature based on state
    const temperature = weatherInfo?.main?.temp
        ? isFahrenheit
            ? `${kelvinToFahrenheit(weatherInfo.main.temp).toFixed(0)}°F`
            : `${kelvinToCelsius(weatherInfo.main.temp).toFixed(0)}°C`
        : "";

    let len = 3;
    let x = detailedGeneratorDataForSite.length;
    len = x === 0 ? 3 : x === 1 ? 3 : x === 2 ? 2 : len;

    return (
        <div className='general'>
            <Row gutter={[16, 16]}>
                <Col {...responsiveColFIrst}>
                    <Row gutter={[16, 16]}>
                        <Col {...responseiveNew2}>
                            <Card className="equal-height-card" size="small">
                                <Row gutter={[16, 16]} justify="center" align="middle" className='heightfull'>
                                    <Col span={24} className='centered-column'>
                                        {permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.customer_logo ?
                                            <img
                                                src={permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.customer_logo}
                                                alt="customerLogo"
                                                className='image-style'
                                            />
                                            :
                                            <Tag><Title>{permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.name}</Title></Tag>}
                                    </Col>
                                    <Col span={24} className='flex-center'>
                                        <Title level={4} className='text-center textTypo'>{selectedSite}</Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        {<Col {...responseiveNew2}>
                            <Spin spinning={weatherInfoLoading || (customerSitesLoading && customerSites.length === 0)}>
                                <Card
                                    style={{ border: 'none' }}
                                    className="equal-height-card"
                                    styles={{ header: { borderColor: "transparent", paddingTop: 6 } }}
                                    size="small"
                                    extra={<img className="level" src={Weather} alt="arrow" />}
                                    title={
                                        <span className="level">
                                            WEATHER
                                        </span>
                                    }
                                >
                                    <Row justify="center" align="middle" className="heightfull">
                                        {weatherInfo !== null && weatherInfoError?.length === 0 ? weatherInfo?.main?.temp ? (
                                            <Col span={24}>
                                                <div className='flex-space-around'>
                                                    <div className='flex-col-center'>
                                                        <div className='flex-col-center'>
                                                            {weatherInfo?.weather[0]?.icon && <img
                                                                src={`https://openweathermap.org/img/wn/${weatherInfo?.weather[0]?.icon}@2x.png`}
                                                                alt="Weather Icon"
                                                                style={{ width: '50px', height: '50px', scale: '2' }}
                                                            />}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <Title
                                                            className="fuel2"
                                                        >
                                                            {`${kelvinToFahrenheit(weatherInfo.main.temp).toFixed(0)}°F`}
                                                        </Title>
                                                    </div>
                                                </div>
                                                {weatherInfo?.weather[0]?.description && <div style={{ textAlign: 'center' }} className='FuelDesc'>{weatherInfo?.weather[0]?.description}</div>}
                                            </Col>
                                        ) : (
                                            <Col span={24}>
                                                <div title='Retry data' className='fuel3' onClick={() => siteLocation?.latitude && siteLocation?.longitude ? dispatch(fetchWeatherData({ lat: siteLocation?.latitude, lon: siteLocation?.longitude, selectedCustomerName })) : ""}>No Weather Data Available</div>
                                            </Col>
                                        ) : <Col span={24}>
                                            <div title='Retry data' className='fuel3' onClick={() => siteLocation?.latitude && siteLocation?.longitude ? dispatch(fetchWeatherData({ lat: siteLocation?.latitude, lon: siteLocation?.longitude, selectedCustomerName })) : ""}>No Weather Data Available</div>
                                        </Col>}
                                    </Row>
                                </Card>
                            </Spin>
                        </Col>}
                        {<Col {...responseiveNew2}>
                            <Spin spinning={customerSitesLoading && customerSites.length === 0}>
                                <Card
                                    style={{ border: "none" }}
                                    className="equal-height-card"
                                    styles={{ header: { borderColor: "transparent" } }}
                                    size="small"
                                    extra={<img className="level" src={StarLinkGreen2} alt="arrow" />}
                                    title={
                                        <span className="level">
                                            TELECOM/CAMERA
                                        </span>
                                    }
                                >
                                    <Row justify="center" align="middle" className="heightfull">
                                        {customerSites.length > 0 ? (
                                            <Col flex={"auto"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {/* Telecom icon */}
                                                    <img
                                                        src={status ? TelecomGreen : TelecomRed}
                                                        style={{ width: "35px" }}
                                                        alt={status ? "TelecomGreen" : "TelecomRed"}
                                                    />
                                                    <Divider type="vertical" />

                                                    {/* Camera icon */}
                                                    {customerSites.find((site) => site.name === selectedSite)?.preferences?.camera_link ? (
                                                        <Link to={`/${selectedCustomerName}/${selectedSite}/${CAMERAS}`}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    width: "35px",
                                                                    height: "35px",
                                                                    borderRadius: "50%",
                                                                    backgroundColor: green,
                                                                    color: "white",
                                                                }}
                                                            >
                                                                <VideoCameraOutlined style={{ fontSize: "24px" }} />
                                                            </div>
                                                        </Link>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "35px",
                                                                height: "35px",
                                                                borderRadius: "50%",
                                                                backgroundColor: grey,
                                                                color: "white",
                                                            }}
                                                        >
                                                            <VideoCameraOutlined style={{ fontSize: "24px" }} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col span={24}>
                                                <Title level={1} className="fuel2">No data</Title>
                                            </Col>
                                        )}
                                    </Row>
                                </Card>

                            </Spin>
                        </Col>}
                        {<Col {...responseiveNew2}>
                            <Spin spinning={selectedCustomerName === 'bchydro' ? fetchLiveDataAcrossSitesSourceIdsLoading || loadingGenDetailsForSite : loadingGenDetailsForSite}>
                                <Card
                                    style={{ border: 'none' }}
                                    className="equal-height-card"
                                    styles={{ header: { borderColor: "transparent", paddingTop: 6 } }}
                                    size="small"
                                    extra={<img className="level" src={FuelGreen} alt="arrow" />}
                                    title={
                                        <span className="level">
                                            FUEL %
                                        </span>
                                    }
                                >
                                    <Row justify="center" align="middle" className="heightfull">
                                        {filteredFuel.length > 0 ? (
                                            <Col span={24}>
                                                <Title level={1} className='fuel2'>{fuelSum}</Title>
                                            </Col>
                                        ) : (
                                            <Col span={24}>
                                                <Title level={1} className='fuel2'>{!presentComponents?.includes(FUEL) ? 'N/A' : 0}</Title>
                                            </Col>
                                        )}
                                    </Row>
                                </Card>
                            </Spin>
                        </Col>}
                    </Row>
                </Col>
                <Col {...responsiveCol}>
                    <Row gutter={[16, 16]}>
                        <Col {...responsiveColTwo}>
                            <Spin spinning={generalDetailsLoading}>
                                <Card
                                    size="small"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: 'none',
                                        width: '100%',
                                        height: 'calc(100% - 20px)', // Calculate height dynamically with padding adjustment
                                        overflow: 'hidden',
                                        paddingTop: '10px',
                                        paddingBottom: '10px'
                                    }}
                                    hoverable
                                    className='equal-height-card2'
                                >
                                    {Object.keys(generalDetails).length !== 0 ? (
                                        <Image
                                            src={generalDetails.Truck_ImageUrl}
                                            alt="Truck"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'fill' // Stretch and shrink the image to fill the card
                                            }}
                                        />
                                    ) : (
                                        <Empty description="Image and site data not found for this site." />
                                    )}
                                </Card>
                            </Spin>
                        </Col>
                        {<Col {...responsiveColTwo}>
                            <Card styles={{ header: { borderColor: "transparent", paddingTop: 6 } }}
                                // extra={<img className='level' src={arrow} alt="arrow" />} 
                                title={<span className='level'><img src={mapsLogo} alt='Fuel' /> LOCATION <div className='space-between-center'>
                                    {selectedSiteLocationUpdated && <div>
                                        <Title level={5} className='ellipsis' style={{ color: '#888' }}>Last updated: {selectedTimezone === 'UTC' ? dayjs.utc(selectedSiteLocationUpdated).format('YYYY-MM-DD HH:mm:ss') : dayjs(selectedSiteLocationUpdated).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss')} {selectedTimezoneAbb}</Title>
                                    </div>}
                                    {!selectedSiteLocationUpdated && <div>
                                        <span style={{ color: '#888' }}>GPS OFF</span>
                                    </div>}
                                </div></span>}
                                hoverable
                                size="small"
                                className="cardStyle equal-height-card2 heightfull"
                                onClick={handleMapClick}
                            >
                                {/* <Divider type='horizontal' variant='solid' /> */}
                                <Maps cardHeight="220px" enableControls={false} main={false} />
                            </Card>
                        </Col>}
                    </Row>
                </Col>
            </Row>
            <div className='h-16' />
            <Row gutter={[16, 16]} style={{ display: 'flex' }}>
                {presentComponents?.includes(POWER_GENERATION) && (
                    <Col flex={"auto"} style={{ display: 'flex' }}>
                        <Card className="equal-height-card4 sharp-edges-card" style={{ flex: 1 }} styles={{ body: { padding: 0 } }}>
                            <Spin spinning={loadingGenDetailsForSite && detailedGeneratorDataForSite.length === 0}>
                                <Table
                                    className='sharp-edges-table'
                                    columns={columnsGen}
                                    dataSource={dataSource.sort((a: any, b: any) => a.data[0].equipment_name.localeCompare(b.data[0].equipment_name))}
                                    pagination={false}
                                    size="small"
                                    style={{ borderRadius: 0 }}
                                />
                            </Spin>
                        </Card>
                    </Col>
                )}
                {presentComponents?.includes(CHARGERS) && (
                    <Col flex={"auto"} style={{ display: 'flex' }}>
                        <Card className="equal-height-card4 sharp-edges-card" style={{ flex: 1 }} styles={{ body: { padding: 0 } }}>
                            {/* Full Width Title */}
                            <Row
                                style={{
                                    padding: '8px',
                                    backgroundColor: '#fafafa',
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                    borderBottom: '1px solid #f0f0f0',
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    textAlign: 'center',
                                }}
                            >
                                <Col span={24}>
                                    CHARGERS
                                </Col>
                            </Row>

                            <Spin
                                spinning={
                                    loadingDetailedChargerDataForSite &&
                                    detailedChargerDataForSite.connector.length === 0 &&
                                    detailedChargerDataForSite.session.length === 0 &&
                                    detailedChargerDataForSite.all_connected_chargers.length === 0
                                }
                            >
                                {dataSourceCharger.length > 0 ? dataSourceCharger.sort((a: any, b: any) => a.chargerName.localeCompare(b.chargerName)).reduce((acc: any[], item: any, index: number) => {
                                    // Group every 3 items together
                                    if (index % len === 0) {
                                        acc.push(dataSourceCharger.slice(index, index + len)); // Create a new group every 3 items
                                    }
                                    return acc;
                                }, []).map((group: any[], groupIndex: number) => (
                                    <Row gutter={[16, 16]} key={groupIndex}>
                                        {group.map((item: any) => (
                                            <Col key={item.key} span={[0, 1].includes(len) || dataSourceCharger.length === 1 ? 24 : len === 2 || dataSourceCharger.length === 2 ? 12 : 8} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Row
                                                    style={{
                                                        display: 'flex',
                                                        padding: '8px',
                                                        width: '100%',
                                                        alignItems: 'center',
                                                        borderBottom: '1px solid #f0f0f0',
                                                    }}
                                                >
                                                    {/* Charger Name Column */}
                                                    <Col span={12} style={{ fontWeight: 500 }}>
                                                        {item.chargerName}
                                                    </Col>

                                                    {/* ColorCircle and Status Column */}
                                                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Popover content={<div>{item.status}</div>}>
                                                            <Link to={`/${selectedCustomerName}/${selectedSite}/${CHARGERS}/${item.key}`}>
                                                                <ColorCircle
                                                                    color={
                                                                        item.status === 'Available'
                                                                            ? green
                                                                            : ['Charging', 'Preparing'].includes(item.status)
                                                                                ? yellow
                                                                                : ['Faulted', 'Suspended EV'].includes(item.status)
                                                                                    ? red
                                                                                    : grey
                                                                    }
                                                                    value={-123} // Adjust value as needed for ColorCircle component
                                                                />
                                                            </Link>
                                                        </Popover>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                )) :
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', alignSelf: 'center', padding: '8px' }}>
                                        <Popover content={<div>No Chargers Found</div>}>
                                            <>
                                                <ColorCircle
                                                    color={grey}
                                                    value={-1} // Adjust value as needed for ColorCircle component
                                                />
                                            </>
                                        </Popover></div>}
                            </Spin>
                        </Card>
                    </Col>
                )}
            </Row>
            <div className='h-16' />
            <Row>
                <Col span={24}>
                    <Card styles={{ header: { borderColor: "transparent" } }} className='custom-scrollbar scrollable-column' size='small'>
                        <Table
                            loading={generalDetailsLoading}
                            dataSource={data}
                            size="middle"
                            columns={columns}
                            bordered={false}
                            pagination={false}
                            style={{ maxHeight: '340px !important' }}
                        />
                    </Card>
                </Col>
            </Row>
        </div >
    );
}

export default General;
