import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { Spin, Row, Col, Typography, Alert, Layout, Drawer, Button, Menu, Dropdown, Avatar, theme, Switch, Select, Skeleton, Modal } from 'antd'
import './AppContent.css';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchAllCustomerSites, fetchAllPermittedCustomers, fetchGeneralDetails, fetchUserInfo, logoutUser } from "../../redux/services/customerService";
import { clearUserInfo, clearWeatherInfo, setIconTheme, setSelectedCustomerName, setSelectedSite, setSelectedTimezone, setSelectedTimezoneAbb } from "../../redux/slices/customerSlice";
import _ from "lodash";
import UserLogo from '../../assets/Icons/user.226x256.png';
import Logout from "./LogOut";
import { clearPowerState, setSelectedFuelId, setSelectedFuelSourceId, setSelectedGeneratorId, setSelectedSourceId } from "../../redux/slices/powerSlice";
import { clearChargerData } from "../../redux/slices/chargerSlice";
import { clearDashboardData, clearGeneralData, setLoading, setLoadingData, updateCustomerResponse } from "../../redux/slices/dashboardSlice";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { BellOutlined, EnvironmentOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, SolutionOutlined, UserOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import LogoSmall from "../../assets/logo/logoSmall.png";
import LogoDark from "../../assets/logo/LogoDarkV2.svg";
import { MenuProps } from "antd/lib";
import factoryGreen from '../../assets/Dashboard/FactoryIconGreen.svg'
import { truncateByCharacterCount } from "../../utils/common";
import globalDashboard from '../../assets/Dashboard/database-01-stroke-rounded 1.png';
import globalDashboardGreen from '../../assets/sideNav/selected/Global.svg';
import Maps from '../../assets/sideNav/Maps.svg';
import MapsGreen from '../../assets/sideNav/selected/Maps.svg';
import factory from '../../assets/Dashboard/FactoryIcon.svg';
import { ALARMS, CAMERAS, CHARGERS, DOCS, FUEL, GENERAL, MAPS, POWER_GENERATION, REPORTS, SOLAR, STARLINK } from "../../utils/constants";
import General from '../../assets/sideNav/General.svg';
import GeneralGreen from '../../assets/sideNav/selected/General.svg';
import Charger from '../../assets/sideNav/Charger.svg';
import ChargerGreen from '../../assets/sideNav/selected/Charger.svg';
import PowerSVG from '../../assets/sideNav/Power.svg';
import PowerGreen from '../../assets/sideNav/selected/Power.svg';
import Fuel from '../../assets/sideNav/Fuel.svg';
import FuelGreen from '../../assets/sideNav/selected/Fuel.svg';
import Solar from '../../assets/sideNav/Solar.svg';
import SolarGreen from '../../assets/sideNav/selected/Solar.svg';
import StarLink from '../../assets/sideNav/StarLink.svg';
import StarLinkGreen from '../../assets/sideNav/selected/StarLink.svg';
import Reports from '../../assets/sideNav/Reports.svg';
import ReportsGreen from '../../assets/sideNav/selected/Reports.svg';
import Alarms from '../../assets/sideNav/Alarms.svg';
import AlarmsGreen from '../../assets/sideNav/selected/Alarms.svg';
import Cameras from '../../assets/sideNav/Camera.svg';
import CamerasGreen from '../../assets/sideNav/selected/Camera.svg';
import Docs from '../../assets/sideNav/Docs.svg';
import DocsGreen from '../../assets/sideNav/selected/Docs.svg';
import MapsGlobal from "../maps/MapsGlobal";






var moment = require('moment-timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

export default function AppContent() {
    const navigate = useNavigate();
    const location = useLocation();
    const FactoryPath = window.location.pathname.toLowerCase() === '/factorydashboard'
    const [authorizationHandled, setAuthorizationHandled] = useState<boolean>(false);
    const [loginStatus, setLoginStatus] = useState<string>('');
    const [authLoading, setAuthLoading] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [selectedKey, setSelectedKey] = useState<string>('DASHBOARD');
    const [showMaps, setShowMaps] = useState<boolean>(false);
    const { detailedChargerDataForSite, loadingDetailedChargerDataForSite, responses, detailedGeneratorData, loadingGenDetails } = useSelector((state: RootState) => state.dashboard);
    const { generatorList, fuelGenList, loading: loadingPower } = useSelector((state: RootState) => state.power);



    const {
        token: { colorBgContainer },
    } = theme.useToken();




    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    const handleSiderClose = () => {
        setCollapsed(!collapsed);
    };

    const dispatch = useDispatch<AppDispatch>();
    const { permittedCustomers,
        permittedCustomersLoading,
        permittedCustomersError,
        customerSites,
        selectedCustomerName,
        selectedTimezone,
        loadingLogout,
        selectedSite,
        userInfo,
        loading,
        customerSitesLoading,
        theme: iconThemeFromRedux,
        customerSitesError } = useSelector((state: RootState) => state.userInfo);

    const [iconTheme, setIconThemeLocal] = useState<number>(iconThemeFromRedux);

    const presentComponents = customerSites.find((site) => site.name === selectedSite)?.preferences?.present_components;

    const isDisabled = permittedCustomers.length === 1 || permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading);


    const { customer, site }: any = useParams();
    const { chargerId: chargerIdFromURL, powerId: powerIdFromURL, fuelId }: any = useParams();


    useEffect(() => {
        if (customer === selectedCustomerName && selectedCustomerName || permittedCustomers.length > 0) {
            setLoginStatus('success');
        }
    }, [selectedCustomerName, customer, permittedCustomers])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (selectedSite)
            dispatch(fetchGeneralDetails({ selectedCustomerName, avail_sites: customerSites, selectedSite }))
    }, [selectedSite])

    const changeTheme = (checked: boolean) => {
        dispatch(setIconTheme(checked ? 1 : 0));
    };

    const signOut = () => {
        dispatch(logoutUser()).then(() => {
            initiateLogin();
        });
    };


    const items: MenuProps['items'] = [
        {
            key: 'profile',
            label: (
                <span>
                    <SolutionOutlined /> View Profile
                </span>
            ),
        },
        {
            key: 'switch',
            label: (
                <div>
                    Theme : &nbsp;
                    <Switch
                        checkedChildren="circle"
                        unCheckedChildren="simple"
                        defaultChecked={iconTheme === 0 ? false : true}
                        onChange={(e) => changeTheme(e)}
                    />
                </div>
            ),
        },
        {
            key: 'logout',
            onClick: signOut,
            label: (
                <span>
                    <PoweroffOutlined /> Log Out
                </span>
            ),
        },
    ];

    const getItem = (label: string, key: string, icon: JSX.Element | undefined, children: null, onClick: any, disabled?: boolean, onTitleClick?: any) => {
        return {
            label,
            key,
            icon,
            children,
            onClick,
            disabled,
            onTitleClick
        };
    };

    const getSelectedKey = (path: string) => {
        if (FactoryPath) {
            return 'FACTORY_DASHBOARD';
        } else if (customer === selectedCustomerName && !_.isEmpty(selectedCustomerName) && _.isEmpty(selectedSite)) {
            return 'Dashboard';
        } else if (path.toLowerCase().includes(POWER_GENERATION.toLowerCase())) {
            return POWER_GENERATION; // Set POWER_GENERATION as selected
        } else if (path.toLowerCase().includes(CHARGERS.toLowerCase())) {
            return 'Chargers'; // Set POWER_GENERATION as selected
        } else if (path.toLowerCase().includes(FUEL.toLowerCase())) {
            return FUEL; // Set FUEL as selected
        } else if (path.toLowerCase().includes(CHARGERS.toLowerCase())) {
            return CHARGERS; // Set FUEL as selected
        } else if (path.toLowerCase().includes(MAPS.toLowerCase())) {
            return MAPS; // Set MAPS as selected
        } else if (path.toLowerCase().includes(REPORTS.toLowerCase())) {
            return REPORTS; // Set REPORTS as selected
        } else if (path.toLowerCase().includes(CAMERAS.toLowerCase())) {
            return CAMERAS; // Set CAMERAS as selected
        } else if (path.toLowerCase().includes(ALARMS.toLowerCase())) {
            return ALARMS; // Set ALARMS as selected
        } else if (path.toLowerCase().includes(SOLAR.toLowerCase())) {
            return SOLAR; // Set SOLAR as selected
        }
        else if (path.toLowerCase().includes(GENERAL.toLowerCase())) {
            return GENERAL; // Set GENERAL as selected
        } else if (path.toLowerCase().includes(STARLINK.toLowerCase())) {
            return STARLINK;
        } else if (path.toLowerCase().includes(DOCS.toLowerCase())) {
            return DOCS;
        }
        return ''; // Return an empty string if no match
    };

    useEffect(() => {
        const selectedKey = getSelectedKey(location.pathname);
        setSelectedKey(selectedKey);
    }, [location.pathname, selectedCustomerName, selectedSite])

    const initialItems = [
        getItem('FACTORY VIEW', 'FACTORY_DASHBOARD', <img src={selectedKey === 'FACTORY_DASHBOARD' ? factoryGreen : factory} className='colorChangeWhenSelected' />, null, () => navigate(`/FactoryDashboard`)),
        getItem('DASHBOARD', 'Dashboard', <img src={selectedKey === 'Dashboard' ? globalDashboardGreen : globalDashboard} className='colorChangeWhenSelected' />, null, () => (navigate(`/${selectedCustomerName}`), setSelectedKey('Dashboard'))),
        getItem('GLOBAL_MAPS', 'GLOBAL_MAPS', <img src={selectedKey === 'GLOBAL_MAPS' ? MapsGreen : Maps} className='colorChangeWhenSelected' />, null, () => (setShowMaps(true), setSelectedKey('GLOBAL_MAPS'))),
        getItem('GENERAL', 'General', <img src={selectedKey === GENERAL ? GeneralGreen : General} alt="general" className='colorChangeWhenSelected' />, null, () => (setSelectedKey(GENERAL), navigate(`/${selectedCustomerName}/${selectedSite}/${GENERAL}`))),
    ];

    const [menuItems, setMenuItems] = useState(initialItems);

    const handleChargerMenuItemClick = (e: any) => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${CHARGERS}/${e.key}`);
    };

    const handleChargerClick = (item: any) => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${CHARGERS}`);
    }

    const handleMenuItemClick = (item: string) => {
        if (item === SOLAR) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${item}`);
        } else if (item === REPORTS) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${item}`);
        } else if (item === CAMERAS) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${item}`);
        } else if (item === ALARMS) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${item}`);
        } else if (item === MAPS) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${item}`);
        }
        else if (item === DOCS) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${item}`);
        }
        else if (item === STARLINK) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${item}`);
        }
        else
            if (item === POWER_GENERATION) {
                if (generatorList && generatorList.length > 0 && !loadingPower) {
                    // dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${generatorList[0].equipment_id}`);
                }
                else if (generatorList && generatorList.length === 0 && !loadingPower) {
                    dispatch(setSelectedGeneratorId(null));
                    dispatch(setSelectedSourceId(''));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}`);
                }
                else {
                    navigate(item)
                }
            }
            else if (item === FUEL) {
                if (fuelGenList && fuelGenList.length > 0 && !loadingPower) {
                    // dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}/${fuelGenList[0].equipment_id}`);
                }
                else if (fuelGenList && fuelGenList.length === 0 && !loadingPower) {
                    dispatch(setSelectedFuelId(null));
                    dispatch(setSelectedFuelSourceId(''));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}`);
                }
                else {
                    navigate(item)
                }
            }
    }

    const getIconForPresentItem = (item: any) => {
        if (item === POWER_GENERATION) {
            return <img src={selectedKey === POWER_GENERATION ? PowerGreen : PowerSVG} alt="power" className='colorChangeWhenSelected' />;
        } else if (item === FUEL) {
            return <img src={selectedKey === FUEL ? FuelGreen : Fuel} alt="fuel" className='colorChangeWhenSelected' />;
        } else if (item === SOLAR) {
            return <img src={selectedKey === SOLAR ? SolarGreen : Solar} alt="solar" className='colorChangeWhenSelected' />;
        } else if (item === STARLINK) {
            return <img src={selectedKey === STARLINK ? StarLinkGreen : StarLink} alt="starlink" className='colorChangeWhenSelected' />;
        } else if (item === REPORTS) {
            return <img src={selectedKey === REPORTS ? ReportsGreen : Reports} alt="report" className='colorChangeWhenSelected' />;
        } else if (item === CAMERAS) {
            return <img src={selectedKey === CAMERAS ? CamerasGreen : Cameras} alt="camera" className='colorChangeWhenSelected' />;
        } else if (item === ALARMS) {
            return <img src={selectedKey === ALARMS ? AlarmsGreen : Alarms} alt="alarm" className='colorChangeWhenSelected' />;
        } else if (item === MAPS) {
            return <img src={selectedKey === MAPS ? MapsGreen : Maps} alt="alarm" className='colorChangeWhenSelected' />;
        } else if (item === DOCS) {
            return <img src={selectedKey === DOCS ? DocsGreen : Docs} className='colorChangeWhenSelected' />;
        }
    }

    let chargerMenuItems: any = [];

    chargerMenuItems = detailedChargerDataForSite.all_connected_chargers.map(data => getItem(data.charger_title, data.id + '', undefined, null, null, loadingDetailedChargerDataForSite)); // Extract names from chargerData   



    useEffect(() => {

        // Start with the base items array
        let updatedItems = [...initialItems];
        setMenuItems(updatedItems);
        // Conditionally remove "Introduction" based on userInfo.show_intro
        if (userInfo && userInfo.show_intro === false) {
            updatedItems = updatedItems.filter(item => item.key !== 'Introduction');
        }
        if (customerSites.length === 1) {
            updatedItems = updatedItems.filter(item => item.key !== 'Dashboard');
        }

        // Add items based on presentComponents
        presentComponents?.forEach((item) => {
            if (item === CHARGERS) {
                updatedItems.push({
                    label: 'CHARGERS',
                    key: 'Chargers',
                    icon: <img src={selectedKey === CHARGERS ? ChargerGreen : Charger} alt="charger" className='colorChangeWhenSelected' />,
                    children: chargerMenuItems,
                    onClick: handleChargerMenuItemClick,
                    disabled: false,
                    onTitleClick: () => handleChargerClick(item),
                });
            } else if (item === POWER_GENERATION) {
                updatedItems.push({
                    label: POWER_GENERATION.toLocaleUpperCase(),
                    key: POWER_GENERATION,
                    icon: <img src={selectedKey === POWER_GENERATION ? PowerGreen : PowerSVG} alt="power" className='colorChangeWhenSelected' />,
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: loadingPower,
                    onTitleClick: () => handleMenuItemClick(item),
                });
            } else if (item === FUEL) {
                updatedItems.push({
                    label: FUEL.toLocaleUpperCase(),
                    key: FUEL,
                    icon: <img src={selectedKey === FUEL ? FuelGreen : Fuel} alt="fuel" className='colorChangeWhenSelected' />,
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: loadingPower,
                    onTitleClick: () => handleMenuItemClick(item),
                });
            } else {
                updatedItems.push({
                    label: item.toLocaleUpperCase(),
                    key: item,
                    icon: getIconForPresentItem(item),
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: undefined,
                    onTitleClick: undefined,
                });
            }
        });

        // Update the menuItems state
        setMenuItems(updatedItems);

    }, [userInfo, presentComponents, loadingPower, detailedChargerDataForSite, selectedKey]); // userInfo as dependency

    const clearCookie = () => {
        axios
            .post(`${baseUrl}microapi/logout-cleanup/`, {}, {
                withCredentials: true,
            })
            .then((response) => {
                // Handle the response as needed
                initiateLogin();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const callUserApi = () => {
        setAuthLoading(false);
        dispatch(fetchUserInfo());
        _.isEmpty(permittedCustomers) && dispatch(fetchAllPermittedCustomers());
    }

    useEffect(() => {
        if (permittedCustomers.length > 0 && selectedCustomerName !== customer) {
            // Check if selectedCustomerName exists in permittedCustomers
            const isValidCustomer = permittedCustomers.some(
                (customer) => customer.customer === selectedCustomerName
            );

            if (isValidCustomer) {
                dispatch(fetchAllCustomerSites(selectedCustomerName)).then((response: any) => {
                    const sites = response?.payload || [];
                    if (sites.length === 1) {
                        dispatch(setSelectedSite(sites[0].name));
                        navigate(`/${selectedCustomerName}/${sites[0].name}/${GENERAL}`);
                    } else {
                        dispatch(setSelectedSite(''));
                        navigate(`/${selectedCustomerName}`);
                    }
                });
            }
        }
        else if (selectedCustomerName === customer && permittedCustomers.length > 0 && customerSites.length === 0 && site) {
            const isValidCustomer = permittedCustomers.some(
                (customer) => customer.customer === selectedCustomerName
            );

            if (isValidCustomer) {
                dispatch(fetchAllCustomerSites(selectedCustomerName)).then((response: any) => {
                    const sites = response?.payload || [];
                    if (sites.find((x: any) => x.name.toLowerCase() === site.toLowerCase())) {
                        dispatch(setSelectedSite(site));
                    }
                })
            }
        }
        else if (selectedCustomerName === customer && permittedCustomers.length > 0 && customerSites.length === 0 && _.isEmpty(site)) {
            console.log("selectedCustomerName === customer && permittedCustomers.length > 0");
            const isValidCustomer = permittedCustomers.some(
                (customer) => customer.customer === selectedCustomerName
            );

            if (isValidCustomer) {
                dispatch(fetchAllCustomerSites(selectedCustomerName)).then((response: any) => {
                    const sites = response?.payload || [];
                    if (sites.length === 1) {
                        dispatch(setSelectedSite(sites[0].name));
                        navigate(`/${selectedCustomerName}/${sites[0].name}/${GENERAL}`);
                    }
                    else {
                        navigate(`/${selectedCustomerName}`);
                    }
                })
            }
        }
    }, [selectedCustomerName, permittedCustomers]);


    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code');
        if (localStorage.getItem('user') === 'true') {
            // navigate('/home');
            callUserApi();
        } else if (code && !authorizationHandled) {
            setAuthorizationHandled(true);
            handleAuthorizationCode(code);
        } else {
            clearCookie();
        }
    }, [location.search]);


    const protocol: any = process.env.REACT_APP_PROTOCOL;
    const url: any = process.env.REACT_APP_API_URL;
    const baseUrl = protocol + url;
    // const reactDashboardUrl = process.env.REACT_APP_URL;

    const initiateLogin = () => {
        const authUrl: any = process.env.REACT_APP_AUTH_URL;
        // Redirect the user to the OAuth2 authorization URL
        window.location.href = authUrl;
    };

    const handleAuthorizationCode = (code: string) => {
        setAuthLoading(true);
        // Create FormData object
        const formData = new FormData();

        // Append the authorization code to the FormData
        formData.append("code", code);

        // Perform the axios request
        axios
            .post(`${baseUrl}oauth/auth0/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
                }, withCredentials: true,
            })
            .then((response) => {
                // Handle the response as needed
                // Now that the authentication is successful, switch to sending cookies
                axios.defaults.withCredentials = true;

                localStorage.setItem('user', 'true');
                setLoginStatus('success');
                // navigate("/Home");
                navigate("/", { replace: true });
                // callUserApi();
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    clearCookie();
                }
                setLoginStatus('error');
                if (error.response.status === 400) {
                    navigate("/errorLogin");
                }
                // initiateLogin();
            }).finally(() => {
                setAuthLoading(false);
            });
    };

    useEffect(() => {
        if (customer && !_.isEmpty(permittedCustomers) && _.isEmpty(site) && !FactoryPath && selectedCustomerName !== customer) {
            if (permittedCustomers.find(data => data.customer === customer)) {
                dispatch(setSelectedCustomerName(customer));
                dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
            }
            else {
                dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
            }
        }
        else if (!_.isEmpty(customer) && !_.isEmpty(site) && !_.isEmpty(permittedCustomers) && !FactoryPath) {
            if (permittedCustomers.find(data => data.customer === customer)) {
                dispatch(setSelectedCustomerName(customer));
                dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
            }
        }
        else if (_.isEmpty(customer) && _.isEmpty(site) && !_.isEmpty(permittedCustomers) && !FactoryPath) {
            dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
            dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
        }
    }, [customer, permittedCustomers])

    const getAbbreviation = (tz: string) => {
        if (!tz) return 'PST'; // Default fallback if no timezone provided

        // Convert current time to the given timezone using moment-timezone
        const nowMoment = moment().tz(tz);

        // Fetch timezone abbreviation using 'z'
        const abbreviation = nowMoment.format('z');

        // If abbreviation fails (if it returns 'z'), return the offset instead
        return abbreviation !== 'z' ? abbreviation : nowMoment.format('ZZ');
    };

    useEffect(() => {
        if (selectedTimezone) {
            const abbreviation = getAbbreviation(selectedTimezone);
            dispatch(setSelectedTimezoneAbb(abbreviation || 'PST'));
        }
    }, [selectedTimezone, dispatch]);

    useEffect(() => {
        if (!permittedCustomersLoading && !_.isEmpty(permittedCustomers) && !_.isEmpty(customer) && !_.isEmpty(site) && !FactoryPath) {
            const permittedCustomerExists = permittedCustomers.find(data => data.customer === customer);
            if (_.isEmpty(permittedCustomerExists) && !_.isEmpty(customer)) {
                window.location.href = `/`;
            }
        }
    }, [permittedCustomersLoading, permittedCustomers, userInfo]);

    useEffect(() => {
        if (!_.isEmpty(customer) && !_.isEmpty(site) && customer !== selectedCustomerName && site !== selectedSite && !_.isEmpty(selectedCustomerName) && !_.isEmpty(selectedSite) && !FactoryPath) {
            if (customer !== selectedCustomerName || (selectedCustomerName === '')) {
                dispatch(clearUserInfo());
                dispatch(clearWeatherInfo());
                dispatch(clearPowerState());
                dispatch(clearChargerData());
                const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);
                if (selectedCustomerObj) {
                    dispatch(setSelectedCustomerName(customer));
                    dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                }
            }
            else if (site !== selectedSite) {
                const siteExists = customerSites.find((siteData: any) => siteData.name === site);
                if (siteExists) {
                    dispatch(setSelectedSite(site));
                }
            }
        }
        else if ((_.isEmpty(site) && !_.isEmpty(customer) && customer !== selectedCustomerName) || (!_.isEmpty(customer) && customer !== selectedCustomerName) || (!_.isEmpty(customer) && customer !== selectedCustomerName && _.isEmpty(selectedSite)) && !FactoryPath) {

            // Clear relevant data
            dispatch(clearUserInfo());
            dispatch(clearPowerState());
            dispatch(clearChargerData());
            dispatch(clearDashboardData());

            // Update customer and site if conditions are met
            if (!_.isEmpty(customer) && !_.isEmpty(site) && permittedCustomers?.length > 0) {
                const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);

                if (selectedCustomerObj) {
                    dispatch(setSelectedCustomerName(customer));
                    dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                }
            }
        }
    }, [customer, site, userInfo])

    // useEffect(() => {
    //     if (!_.isEmpty(customer) && _.isEmpty(site) && !FactoryPath) {
    //         if (permittedCustomers?.length > 0) {
    //             dispatch(setSelectedCustomerName(customer));
    //             dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
    //         }
    //     }
    // }, [customer, permittedCustomers])

    useEffect(() => {
        if (permittedCustomers.length > 0 && FactoryPath && responses.length === 0) {
            dispatch(setLoading(true));
            dispatch(setLoadingData(true));

            const fetchCustomerData = async (customerData: { customer: string }) => {
                try {
                    const response = await axios.get(`${baseUrl}trend/live_values_v3`, {
                        withCredentials: true,
                        headers: {
                            customer: customerData.customer,
                        },
                    }); {
                        // Update existing customer data
                        dispatch(updateCustomerResponse({
                            data: response.data,
                            customer: customerData.customer
                        }));
                    }
                    dispatch(setLoading(false));
                } catch (error: any) {
                    dispatch(setLoading(false));
                    console.error(`Error fetching data for customer ${customerData.customer}:`, error?.message);
                }
            };

            // Initial fetch for all customers
            const fetchAllData = async () => {
                const promises = permittedCustomers.map(data => fetchCustomerData(data));
                await Promise.all(promises).finally(() => {
                    dispatch(setLoadingData(false));
                });
            };

            fetchAllData();

            // Set up polling for each customer
            const pollingIntervals = permittedCustomers.map(customerData => {
                return setInterval(() => {
                    fetchCustomerData(customerData);
                }, 60000 * 5); // 5minutes
            });

            // Cleanup function to clear all intervals
            // return () => {
            //     pollingIntervals.forEach(interval => clearInterval(interval));
            // };
        }
    }, [permittedCustomers, location]); // Dependencies array

    useEffect(() => {
        if (FactoryPath) {
            document.title = 'Factory Dashboard: e-BoostRealM';
        }
    }, [FactoryPath]);

    const logo = collapsed ? LogoSmall : LogoDark;


    const handleSelectCustomerChange = (value: string) => {
        dispatch(setSelectedCustomerName(value))
        dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === value)?.preferences?.timezone || 'US/Pacific'));
        dispatch(clearUserInfo());
        dispatch(clearPowerState());
        dispatch(clearWeatherInfo());
        dispatch(clearDashboardData());
        dispatch(clearChargerData());
        // navigate(`/${value}`);
    }
    const currentPathSegments = location.pathname.split('/');

    let lastSegment: any = currentPathSegments[currentPathSegments.length - 1];

    if (chargerIdFromURL) {
        lastSegment = CHARGERS
    }

    if (powerIdFromURL) {
        lastSegment = POWER_GENERATION
    }

    if (fuelId) {
        lastSegment = FUEL
    }

    const handleSelectSiteChange = (value: string) => {
        dispatch(clearChargerData());
        dispatch(clearPowerState());
        dispatch(clearWeatherInfo());
        dispatch(clearWeatherInfo());
        dispatch(clearGeneralData());
        dispatch(setSelectedSite(value));
        const selectedSiteObj = customerSites.find((data) => data.name === value)
        if (selectedSiteObj) {
            const present_components = selectedSiteObj?.preferences?.present_components;
            if (present_components.includes(lastSegment) && ![POWER_GENERATION, FUEL].includes(lastSegment)) {
                navigate(`/${selectedCustomerName}/${value}/${lastSegment}`);
            }
            else if (present_components.includes(lastSegment) && [POWER_GENERATION, FUEL].includes(lastSegment)) {
                // if (selectedCustomerName === 'bchydro')
                //     navigate(`/${selectedCustomerName}/${value}/${GENERAL}`)
            }
            else {
                navigate(`/${selectedCustomerName}/${value}/${GENERAL}`);
            }
        }
    }

    function capitalizeFirstLetter(str: string) {
        if (!str) return str;  // Check for empty string or null/undefined
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const siderContent = (
        <Row>
            <Col>
                {!isMobile && <div className='imageDiv'>
                    <img
                        src={logo}
                        alt="logo"
                        className='actualImage'
                    />
                </div>}
                {isMobile && !FactoryPath && <div className="selectDiv">
                    <Select
                        value={selectedCustomerName}
                        loading={permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading)}
                        showSearch
                        options={permittedCustomers.map(item => ({ label: item.name, value: item.customer }))}
                        suffixIcon={<img src={UserLogo} className='h-18' />}
                        onChange={handleSelectCustomerChange}
                        disabled={isDisabled}
                        className={isDisabled ? 'insideSelectMobile select-disabled' : 'insideSelectMobile'}
                    />
                </div>}
                {isMobile && !FactoryPath && selectedSite && <div className="selectDiv">
                    <Select
                        disabled={(customerSites.length === 0 && customerSitesLoading) || permittedCustomersLoading}
                        value={selectedSite}
                        showSearch
                        options={customerSites.map(item => ({ label: item.name, value: item.name }))}
                        className='insideSelectMobile'
                        suffixIcon={<EnvironmentOutlined className='h-18 clr-blk' />}
                        onChange={handleSelectSiteChange}
                    />
                </div>
                }
                <Skeleton active={true} title={false} className='margin-5 newLoadingSkeleton' loading={((customerSites.length === 0 && customerSitesLoading) || permittedCustomersLoading)} paragraph={{ rows: 5 }} >
                    <Menu
                        theme={"dark"}
                        mode="inline"
                        defaultSelectedKeys={[selectedCustomerName, selectedSite, capitalizeFirstLetter(lastSegment)]}
                        selectedKeys={[selectedCustomerName, selectedSite, chargerIdFromURL, capitalizeFirstLetter(lastSegment), selectedKey]}
                        items={FactoryPath ? menuItems.filter(item => item.key === 'FACTORY_DASHBOARD') : selectedCustomerName && !selectedSite ? menuItems.filter(item => ['Dashboard', 'FACTORY_DASHBOARD', 'GLOBAL_MAPS'].includes(item.key)) : selectedSite ? menuItems.filter(item => !['GLOBAL_MAPS'].includes(item.key)) : menuItems}
                    />
                </Skeleton>
            </Col>
        </Row>
    );



    return (
        <div className="AppContent">
            <div>
                {/* Loading Screen */}
                {!(loadingLogout === false && loginStatus === 'success' || customerSitesLoading || (FactoryPath && loadingLogout === false && loginStatus === 'success')) && (permittedCustomersLoading && permittedCustomersError.length === 0 || authLoading) && (
                    <Spin size="large" style={{ backgroundColor: 'transparent' }}>
                        <Row justify="center">
                            <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingColApp">
                                <Typography.Title level={2} type="secondary">
                                    Loading. Please wait!
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Spin>
                )}

                {/* Error Alert for Network Issues or Permission Issues */}
                {!loadingLogout && loginStatus !== 'error' && permittedCustomersError?.length > 0 && (
                    <div className="centerContainer">
                        <Alert
                            message={
                                ["Network Error", "Request failed with status code 500"].includes(permittedCustomersError)
                                    ? permittedCustomersError
                                    : "Contact Admin"
                            }
                            description={
                                ["Network Error", "Request failed with status code 500"].includes(permittedCustomersError)
                                    ? "Unable to reach the server"
                                    : "No permission has been added to your account. Please contact e-Boost admin."
                            }
                            type={
                                ["Network Error", "Request failed with status code 500"].includes(permittedCustomersError)
                                    ? "error"
                                    : "warning"
                            }
                            showIcon
                            style={{ width: '100%', fontSize: '20px' }}
                            action={<Logout />}
                        />
                    </div>
                )}

                {/* Alert for No Customers Found */}
                {!loadingLogout && !authLoading && loginStatus !== 'error' && !permittedCustomersLoading && permittedCustomers.length === 0 && permittedCustomersError?.length === 0 && (
                    <div className="centerContainer">
                        <Alert
                            message="No customers found!"
                            description="Please contact your administrator."
                            type="error"
                            showIcon
                            style={{ width: '100%', fontSize: '20px' }}
                            action={<Logout />}
                        />
                    </div>
                )}

                {/* Main Content or Outlet */}
                {(loadingLogout === false && loginStatus === 'success' || customerSitesLoading || (FactoryPath && loadingLogout === false && loginStatus === 'success') &&
                    !(permittedCustomersLoading && permittedCustomersError.length === 0 || authLoading)) &&

                    <Layout>
                        {isMobile ? (
                            <>
                                <Drawer className='clrDrawer'
                                    title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button
                                                type="text"
                                                icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                                                onClick={() => handleSiderClose()}
                                                className='mr-10 text-base'
                                            />
                                            <div>Menu</div>
                                        </div>
                                        <div className='imageDiv'>
                                            <img
                                                src={logo}
                                                alt="logo"
                                                className='actualImage'
                                            />
                                        </div>
                                    </div>}
                                    placement="left"
                                    closable={false}
                                    onClose={() => setCollapsed(!collapsed)}
                                    visible={collapsed}
                                >
                                    {siderContent}
                                </Drawer>
                            </>
                        ) : (
                            <Sider trigger={null} collapsible collapsed={collapsed} theme={isMobile ? "light" : "dark"}>
                                {siderContent}
                            </Sider>

                        )}

                        {<Layout style={{ height: 'inherit' }}>
                            <Header className="header" style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                width: '100%',
                                height: '64px',
                                padding: '0 16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                background: colorBgContainer
                            }}>
                                <div className='collapseDiv'>
                                    <Button
                                        type="text"
                                        icon={collapsed ? isMobile ? <MenuFoldOutlined /> : <MenuUnfoldOutlined /> : isMobile ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                        onClick={() => handleSiderClose()}
                                        className='mr-10 text-base'
                                    />
                                    {!isMobile && !FactoryPath && (
                                        <Select
                                            value={selectedCustomerName}
                                            loading={permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading)}
                                            showSearch
                                            options={permittedCustomers.map((item) => ({ label: item.name, value: item.customer }))}
                                            suffixIcon={<UserOutlined style={{ fontSize: '16px' }} className="clr-blk" />}
                                            onChange={handleSelectCustomerChange}
                                            disabled={isDisabled}
                                            size="large"
                                            className={isDisabled ? 'insideSelect select-disabled' : 'insideSelect'}
                                            style={{ minWidth: '180px', flexShrink: 0 }}
                                        />
                                    )}
                                    {!isMobile && !FactoryPath && selectedSite && (
                                        <Select
                                            disabled={(customerSites.length === 0 && customerSitesLoading) || permittedCustomersLoading}
                                            value={selectedSite}
                                            showSearch
                                            size="large"
                                            options={customerSites.map((item) => ({ label: item.name, value: item.name }))}
                                            className="insideSelect"
                                            suffixIcon={<EnvironmentOutlined style={{ fontSize: '16px' }} className="h-18 clr-blk" />}
                                            onChange={handleSelectSiteChange}
                                            style={{ minWidth: '180px', flexShrink: 0 }}
                                        />
                                    )}
                                    {/* <div>
                                        <ReloadOutlined onClick={() => navigate(0)} />
                                    </div> */}
                                </div>
                                <div className='collapseDiv'>
                                    <Button
                                        type="text"
                                        icon={<BellOutlined className='text-base' />}
                                        className='mr-10 text-base'
                                    >
                                        {/* <span className={collapsed ? '' : 'noShow'}>Notification</span> */}
                                    </Button>
                                    <div className='pointer'>
                                        <Dropdown menu={{ items }}>
                                            <div>
                                                <Avatar
                                                    size={'small'}
                                                    icon={<UserOutlined />}
                                                    className='avatarAdditional'
                                                >
                                                    {userInfo?.first_name && userInfo.first_name.charAt(0)}
                                                </Avatar>
                                                <span title={userInfo?.first_name} className={collapsed ? 'mr-10' : 'mr-10 noShow'}>{truncateByCharacterCount(`${userInfo?.first_name}`, 7)}</span>
                                            </div>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Header>
                            <Content className="content">
                                {customerSitesLoading ? <Spin size="large" style={{ backgroundColor: 'transparent' }}>
                                    <Row justify="center">
                                        <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingColApp">
                                            <Typography.Title level={2} type="secondary">
                                                Loading. Please wait!
                                            </Typography.Title>
                                        </Col>
                                    </Row>
                                </Spin> :
                                    <Outlet />}
                            </Content>
                        </Layout>}
                    </Layout>
                }

                {loadingLogout && (
                    <Spin size="large">
                        <Row justify="center">
                            <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                                <Typography.Title level={2} type="secondary">
                                    Logging you Out. Please wait!
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Spin>
                )}
            </div>
            <Modal mask={true} open={showMaps} footer={null} onCancel={() => (setShowMaps(false), setSelectedKey("Dashboard"))} width={'80%'} destroyOnClose>
                <MapsGlobal loading={loadingGenDetails} enableControls={true} main={true} loc={detailedGeneratorData.map(s => s.site_details)} />
            </Modal>
        </div>

    );
}
