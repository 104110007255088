import { Button, Checkbox, CheckboxOptionType, Col, Empty, Input, Popover, Row, Space, Table, Tooltip } from "antd";
import './General.css';  // Assuming you add the CSS here
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import '../dashboard/Dashboard.css';
import { EyeInvisibleOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setSelectedSite } from "../../redux/slices/customerSlice";
import { CHARGERS, FUEL, GENERAL, POWER_GENERATION, red, blue, yellow, green, grey, faults, alarms } from "../../utils/constants";
import _ from "lodash";
import MainIconTemp3 from "./MainIconTemp3";
import dayjs from "dayjs";
import { TableInterface } from "../../redux/types/dashboardTypes";
import { ColumnsType } from "antd/es/table";
import { setSelectedGeneratorId, setSelectedSourceId } from "../../redux/slices/powerSlice";
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import { toast } from "react-toastify";
import { createStyles } from "antd-style";
// Extend Day.js with the duration plugin
dayjs.extend(duration);
dayjs.extend(timezone);

const useStyle = createStyles(({ css, token }) => {
    return {
        customTable: css`
            .ant-table {
                .ant-table-container {
                    .ant-table-body,
                    .ant-table-content {
                        scrollbar-width: thin;
                        scrollbar-color: ${token.colorBorder} transparent;
                        scrollbar-gutter: stable;

                        &::-webkit-scrollbar {
                            width: 6px;
                            height: 6px;
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: ${token.colorBorder};
                            border-radius: ${token.borderRadius}px;
                        }
                    }
                }
            }
        `
    };
});

interface ColorCircleProps {
    color: string;  // The color for the circle background
    value: number;  // The number or text to display in the center
}



const TableDashboard = () => {
    const { styles } = useStyle();
    const { permittedCustomersLoading, selectedCustomerName, customerSites, customerSitesLoading, theme: iconThemeFromRedux, selectedTimezone, selectedTimezoneAbb } = useSelector((state: RootState) => state.userInfo);
    const { detailedChargerData, loadingDetailedChargerData, detailedGeneratorData, loadingGenDetails } = useSelector((state: RootState) => state.dashboard);
    const loadingLength = Object.keys(detailedChargerData).length > 0 && Object.keys(detailedGeneratorData).length > 0;
    const dispatch = useDispatch<AppDispatch>();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredData, setFilteredData] = useState<TableInterface[]>([]);
    const [generatorData, setGeneratorData] = useState<TableInterface[]>([]);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const [iconTheme, setIconThemeLocal] = useState<number>(iconThemeFromRedux);
    const notify = () => toast(<b>This feature is not available for this site</b>, { type: 'warning' });


    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIconThemeLocal(iconThemeFromRedux);
    }, [iconThemeFromRedux])



    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    useEffect(() => {
        if (customerSites.length > 0) {
            const newData = customerSites.map((site) => ({
                ...site,
                chargerStatus: detailedChargerData?.connector?.filter(item => item.siteId === site.id) || [],
                generatorStatus: detailedGeneratorData?.filter(item => item.site_id === site.id) || [],
                chargerList: detailedChargerData?.all_connected_chargers?.filter(item => item.site_id === site.id) || [],
                requested_schema: detailedGeneratorData[0]?.requested_schema || selectedCustomerName
            }));

            setFilteredData(newData);
        } else {
            setFilteredData([]);
        }
    }, [detailedGeneratorData, detailedChargerData, customerSites]);

    useEffect(() => {
        if (searchQuery.trim() === '' && customerSites.length > 0 && filteredData.length > 0) {
            setGeneratorData(filteredData); // Show all if searchQuery is empty
        } else if (searchQuery.trim() !== '' && customerSites.length > 0 && filteredData.length > 0) {
            const lowercasedQuery = searchQuery.toLowerCase();

            const filtered = filteredData.filter((site) => {
                const siteName = customerSites.find((customerSite) => site.name === customerSite.name)?.name;
                // Adjust this to include the fields you want to search through
                return (
                    siteName?.toLowerCase().includes(lowercasedQuery)
                );
            });

            setGeneratorData(filtered);
        }
    }, [searchQuery, filteredData, customerSites]);

    const handleNavigate = (siteName: string) => {
        navigate(`/${selectedCustomerName}/${siteName}/${GENERAL}`);
    };


    const getChargingSessionsPerDay = (id: any) => {
        if (!loadingDetailedChargerData && detailedChargerData.session && detailedChargerData.session.length > 0) {
            return detailedChargerData.session.filter(item =>
                item.status === 'Ended' &&
                dayjs(item.metrics.chargingStop).isSame(dayjs(), 'day') && item.charger.site === id
            ).length;
        }
        else return 0;
    }

    const ColorCircle: React.FC<ColorCircleProps> = ({ color, value }) => {
        return (
            <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                {/* Circle with dynamic color */}
                <circle cx="17.5" cy="17.5" r="17.5" fill={color} />
                {/* Number/Text in the center */}
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="central"
                    textAnchor="middle"
                    pointerEvents={'all'}
                    fill="white"
                    fontSize="16"
                    fontWeight={400}
                    fontFamily="Arial, sans-serif"
                    dy="0.05em"
                    dx={value > 99 ? '-0.1em' : ''}
                >
                    {value === -123 ? '' : value < 0 ? 'N/A' : value}
                </text>
            </svg>
        );
    };

    const handleGeneratorClick = (name: string, eqpId: number, genId: number, sourceId: string) => {
        const allow = customerSites.find((site) => site.name === name)?.preferences?.present_components.includes(POWER_GENERATION);
        console.log("allow", allow);
        if (allow) {
            dispatch(setSelectedSite(name));
            dispatch(setSelectedGeneratorId(genId));
            dispatch(setSelectedSourceId(sourceId));
            navigate(`/${selectedCustomerName}/${name}/${POWER_GENERATION}/${eqpId}`);
        }
        else {
            notify();
        }
    }

    const handleFuelClick = (name: string, eqpId: number) => {
        const allow = customerSites.find((site) => site.name === name)?.preferences?.present_components.includes(FUEL);
        if (allow) {
            dispatch(setSelectedSite(name));
            navigate(`/${selectedCustomerName}/${name}/${FUEL}/${eqpId}`);
        }
        else {
            notify();
        }
    }

    const columns: ColumnsType<TableInterface> = [
        {
            title: 'TITLE',
            dataIndex: 'location',
            key: '0',
            render: (text: any, record) => <a onClick={() => handleNavigate(record.name)}>{`${record.name}`}</a>,
        },
        {
            title: 'SESSIONS',
            key: '1',
            dataIndex: 'csToday',
            render: (text, record) => <Link onClick={() => dispatch(setSelectedSite(record.name))}
                to={`/${selectedCustomerName}/${record.name}/${CHARGERS}`}>{iconTheme === 0 ? <ColorCircle color={grey} value={record.id ? getChargingSessionsPerDay(record.id) : 0} /> : record.id ? getChargingSessionsPerDay(record.id) < 0 ? '0' : getChargingSessionsPerDay(record.id) : 0}</Link>
        },
        {
            title: 'FUEL (%)',
            key: '2',
            dataIndex: 'fuel',
            render: (text: any, record) => {
                return (
                    <Space size="small">
                        {record.generatorStatus.map((gen, key) => {
                            let sumFuel = 0;
                            let timeStamp = null;
                            // let notliveData = false;
                            gen.equipment_data.forEach((items) => {
                                const genName = items.data[0].equipment_name;
                                // notliveData = items?.LiveStatus !== 'live';

                                if (['EV Charger', 'EV_Charger'].includes(genName) ||
                                    (items.data[0].source_id !== 'BCH-Fuel' && selectedCustomerName === 'bchydro')) return;

                                const fuelLevelRegex = /Fuel Level(\s*\(.*\))?/i;


                                // Simplified fuelValue calculation
                                const fuelValue: any = items.data.find((item) =>
                                    (fuelLevelRegex.test(item.value_name) && item.source_id === 'BCH-Fuel') ||
                                    (fuelLevelRegex.test(item.value_name) && item.source_id !== 'BCH-Fuel')
                                )?.value || 0;

                                timeStamp = items.data.find((item) =>
                                    (fuelLevelRegex.test(item.value_name) && item.source_id === 'BCH-Fuel') ||
                                    (fuelLevelRegex.test(item.value_name) && item.source_id !== 'BCH-Fuel')
                                )?.value_datetime || null;
                                // Parse fuelValue to ensure it's a number
                                sumFuel += parseFloat(fuelValue) || 0;
                            });

                            if (sumFuel === 0) return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : <div key={key}>N/A</div>;

                            timeStamp = timeStamp ? selectedTimezone === 'UTC' ? dayjs.utc(timeStamp).format('YYYY-MM-DD HH:mm:ss') : dayjs(timeStamp).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss') : null;

                            const tableData = [
                                {
                                    key: key,
                                    lastUpdated: timeStamp,
                                },
                            ];

                            const columns = [
                                { title: 'Last Updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                            ];

                            return (
                                <div key={key}>
                                    {iconTheme === 0 ?
                                        <div key={gen.site_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <div style={{ cursor: 'pointer' }}
                                                onClick={() => handleFuelClick(record.name, gen.equipment_data[0].equipment_id)}
                                            >
                                                <Tooltip color="white" styles={{ body: { padding: 0 } }}
                                                    title={<Table
                                                        columns={columns}
                                                        dataSource={tableData}
                                                        pagination={false}
                                                        size="small"
                                                        bordered
                                                    />} > <ColorCircle color={sumFuel < 25 ? red : sumFuel <= 75 ? yellow : green} value={_.round(sumFuel < 0 ? 0 : sumFuel, 0)} /> </Tooltip>
                                            </div>
                                        </div> :
                                        <Tooltip color="white" styles={{ body: { padding: 0 } }}
                                            title={<Table
                                                columns={columns}
                                                dataSource={tableData}
                                                pagination={false}
                                                size="small"
                                                bordered
                                            />} >
                                            <div key={gen.site_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                {sumFuel < 25 ? <MainIconTemp3 fillColor={red} /> :
                                                    sumFuel <= 75 ? <MainIconTemp3 fillColor={yellow} /> :
                                                        <MainIconTemp3 fillColor={green} />}
                                                <div onClick={() => handleFuelClick(record.name, gen.equipment_data[0].equipment_id)}
                                                >{_.round(sumFuel, 2)}</div>
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            );
                        })}
                    </Space>
                );
            },
        },

        {
            title: 'GENERATORS',
            key: '3',
            dataIndex: 'generators',
            render: (text: any, record) => {
                const hasGenerators = record.generatorStatus?.some((gen) =>
                    gen.equipment_data?.some((items) => {
                        const genName = items?.data?.[0]?.equipment_name;
                        return !['EV Charger', 'EV_Charger'].includes(genName) && items?.data?.[0]?.source_id !== 'BCH-Fuel';
                    })
                );

                if (!hasGenerators) {
                    return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : <div key={record.id}>N/A</div>;
                }

                let displayIndex = 1;

                return (
                    <Space size="small">
                        {record.generatorStatus
                            ?.flatMap(gen => gen.equipment_data) // Flatten equipment_data arrays for easier sorting
                            ?.filter(items => {
                                const genName = items?.data?.[0]?.equipment_name;
                                return genName && !['EV Charger', 'EV_Charger'].includes(genName) && items?.data?.[0]?.source_id !== 'BCH-Fuel';
                            })
                            ?.sort((a, b) => {
                                const nameA = a?.data?.[0]?.equipment_name?.toLowerCase() || '';
                                const nameB = b?.data?.[0]?.equipment_name?.toLowerCase() || '';
                                return nameA.localeCompare(nameB);
                            })
                            ?.map((items, key) => {
                                const genName = items?.data?.[0]?.equipment_name;
                                const notliveData = items?.LiveStatus !== 'live';
                                const runningStatus = items?.data?.find((item: any) => item.value_name === 'Running')?.value === 1;

                                const faultStatuses = items?.data
                                    ?.filter(item => faults.includes(item.value_name) && item.value === 1)
                                    ?.map(item => item.value_name) || [];

                                const alarmStatuses = items?.data
                                    ?.filter(item => alarms.includes(item.value_name) && item.value === 1)
                                    ?.map(item => item.value_name) || [];

                                const faultStatusesNegative = items?.data?.some(item =>
                                    item?.value_name && faults.includes(item.value_name) && item.value === -99) ||
                                    items?.data?.some(item =>
                                        item?.value_name && alarms.includes(item.value_name) && item.value === -99
                                    );

                                const engineHourCheck = items?.data?.some(item =>
                                    item?.value_name === 'Engine Hours' && item.value < 0) || false;

                                const lastUpdated = items?.data?.[0]?.value_datetime
                                    ? selectedTimezone === 'UTC'
                                        ? dayjs.utc(items?.data?.[0]?.value_datetime).format('YYYY-MM-DD HH:mm:ss')
                                        : dayjs(items?.data?.[0]?.value_datetime).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss')
                                    : null;

                                const color = notliveData || engineHourCheck || faultStatusesNegative
                                    ? grey
                                    : runningStatus
                                        ? blue
                                        : faultStatuses.length > 0
                                            ? red
                                            : alarmStatuses.filter((alarm) => !['Common Alarm', 'Warning Alarm'].includes(alarm)).length > 0
                                                ? yellow
                                                : green;

                                const tableData = [{
                                    key: displayIndex,
                                    name: genName,
                                    status: notliveData || faultStatusesNegative || engineHourCheck
                                        ? 'Unknown/Offline'
                                        : runningStatus
                                            ? 'Running'
                                            : faultStatuses.length > 0
                                                ? 'Fault'
                                                : alarmStatuses.filter((alarm) => !['Common Alarm', 'Warning Alarm'].includes(alarm)).length > 0
                                                    ? 'Alarm'
                                                    : 'Able to run',
                                    faults: faultStatuses.length === 0 ? 'None' : faultStatuses.join(', '),
                                    alarms: alarmStatuses.length === 0 ? 'None' : alarmStatuses.join(', '),
                                    lastUpdated: lastUpdated
                                }];

                                const columns = [
                                    { title: 'Name', dataIndex: 'name', key: 'name' },
                                    { title: 'Status', dataIndex: 'status', key: 'status' },
                                    { title: 'Faults', dataIndex: 'faults', key: 'faults' },
                                    { title: 'Alarms', dataIndex: 'alarms', key: 'alarms' },
                                    { title: 'Last updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                                ];

                                const currentDisplayIndex = displayIndex;
                                displayIndex++;

                                return (
                                    <Popover
                                        key={currentDisplayIndex + key}
                                        content={
                                            <Table
                                                columns={columns}
                                                dataSource={tableData}
                                                pagination={false}
                                                size="small"
                                                bordered
                                            />
                                        }
                                        color="white"
                                        styles={{ body: { padding: 0 } }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <div style={{ cursor: 'pointer' }} onClick={() => handleGeneratorClick(record?.name, items?.equipment_id, items.data[0]?.equipment_id, items.data[0]?.source_id)}>
                                                {iconTheme === 0 ? (
                                                    <ColorCircle color={color} value={currentDisplayIndex} />
                                                ) : (
                                                    <div key={currentDisplayIndex} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                        <MainIconTemp3 fillColor={color} /> {genName}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Popover>
                                );
                            })}
                    </Space>
                );
            },
        }
        ,
        {
            title: 'MAINTENANCE',
            key: '4',
            dataIndex: 'maintenance',
            render: () => {
                // const engineHours = record.generators[0]?.liveValues.find((item: any) => item.value_name === 'Engine Hours')?.value;
                return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
            },
        },
        {
            title: 'CHARGERS',
            key: '5',
            dataIndex: 'chargers',
            render: (text: any, record) => {
                return record.chargerList.length === 0 ? (
                    iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    )
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {record.chargerList.map((charger, index: number) => {
                            // Find the matching status from chargerStatus for the current charger
                            const matchingStatus = record.chargerStatus.find(
                                (status) => status.charger === charger.id
                            );

                            const connectorStatus = matchingStatus?.connectors?.[0]?.status || '';
                            const staticCharger = charger.api_source === 'static';

                            // Prepare the data for the tooltip table
                            const dataSource = [
                                {
                                    key: '1',
                                    name: charger.charger_title,
                                    status: staticCharger
                                        ? 'Static Charger'
                                        : connectorStatus || 'No Status available/static',
                                },
                            ];

                            // Define columns for the tooltip table
                            const columns = [
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'Status',
                                    dataIndex: 'status',
                                    key: 'status',
                                },
                            ];

                            const handleChargerClick = (chargerId: any) => {
                                const allow = customerSites.find((site) => site.name === record?.name)?.preferences?.present_components?.includes(CHARGERS);
                                if (allow) {
                                    dispatch(setSelectedSite(record.name));
                                    navigate(`/${selectedCustomerName}/${record.name}/${CHARGERS}/${chargerId}`);
                                }
                                else {
                                    notify();
                                }
                            }

                            return (
                                <Tooltip
                                    title={
                                        <Table
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={false}
                                            size="small"
                                            bordered
                                        />
                                    }
                                    color="white"
                                    styles={{ body: { padding: 0 } }}
                                    key={index}
                                >
                                    {iconTheme === 0 ? <Space size="small">
                                        <div
                                            onClick={() => handleChargerClick(charger.id)}
                                            style={{ marginRight: 5, cursor: 'pointer' }}
                                        >
                                            <ColorCircle
                                                value={index + 1}
                                                color={
                                                    connectorStatus === 'Available'
                                                        ? green
                                                        : ['Charging', 'Preparing'].includes(connectorStatus)
                                                            ? yellow
                                                            : ['Faulted', 'Suspended EV'].includes(connectorStatus)
                                                                ? red
                                                                : grey
                                                }
                                            />
                                        </div>
                                    </Space> :
                                        <Space size="small" key={index}>
                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                                <MainIconTemp3
                                                    key={index + charger.id}
                                                    fillColor={
                                                        connectorStatus === "Available"
                                                            ? green
                                                            : ["Charging", "Preparing"].includes(connectorStatus)
                                                                ? yellow
                                                                : ["Faulted", "Suspended EV"].includes(connectorStatus)
                                                                    ? red
                                                                    : grey
                                                    }
                                                />
                                                {/* Display the charger name below the icon */}
                                                <div onClick={() => handleChargerClick(charger.id)} style={{ cursor: 'pointer', marginLeft: 5 }}>
                                                    {charger.charger_title}
                                                </div>
                                            </div>
                                        </Space>}
                                </Tooltip>
                            );
                        })}
                    </div>
                );
            },
        }
        ,
        {
            title: 'TEMP',
            key: '6',
            dataIndex: 'temp',
            render: () => {
                // const engineHours = record.generators[0]?.liveValues.find((item: any) => item.value_name === 'Engine Hours')?.value;
                return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
            },

        },
        {
            title: 'BATTERIES',
            key: '7',
            dataIndex: 'batteries',
            render: (text: any, record) => {
                if (!record.generatorStatus || record.generatorStatus.length === 0) {
                    return iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    );
                }

                const hasBatteryData = record.generatorStatus.some((gen) =>
                    gen.equipment_data?.some((items) =>
                        items.data?.some((item: any) => item.value_name === 'Battery Voltage')
                    )
                );

                if (!hasBatteryData) {
                    return iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    );
                }

                return (
                    <Space size="small">
                        {record.generatorStatus
                            ?.flatMap(gen => gen.equipment_data) // Flatten to sort all equipment_data
                            ?.filter(items => {
                                const genName = items.data[0]?.equipment_name;
                                return genName && !['EV Charger', 'EV_Charger'].includes(genName) && items.data[0]?.source_id !== 'BCH-Fuel';
                            })
                            ?.sort((a, b) => {
                                const nameA = a.data[0]?.equipment_name?.toLowerCase() || '';
                                const nameB = b.data[0]?.equipment_name?.toLowerCase() || '';
                                return nameA.localeCompare(nameB);
                            })
                            ?.map((items, index) => {
                                const batteryData = items.data.find(
                                    (item) => item.value_name === 'Battery Voltage'
                                );
                                const generatorBattery = batteryData?.value ?? 'N/A';
                                const genName = items.data[0]?.equipment_name;
                                const lastUpdated = items.data[0]?.value_datetime
                                    ? selectedTimezone === 'UTC'
                                        ? dayjs.utc(items.data[0]?.value_datetime).format('YYYY-MM-DD HH:mm:ss')
                                        : dayjs(items.data[0]?.value_datetime).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss')
                                    : '';
                                const notliveData = items?.LiveStatus !== 'live';

                                const tableData = [{
                                    key: index,
                                    name: genName || 'Unknown',
                                    batteryVoltage: generatorBattery,
                                    lastUpdated: lastUpdated,
                                    status: generatorBattery === 'N/A' ? 'Unknown/Offline' : notliveData || generatorBattery < 0 ? 'Unknown/Offline' :
                                        generatorBattery === 0 ? 'No Voltage' :
                                            generatorBattery < 10 ? 'Low' :
                                                generatorBattery < 12 ? 'Moderate' : 'Good',
                                }];

                                const columns = [
                                    { title: 'Name', dataIndex: 'name', key: 'name' },
                                    { title: 'Battery Voltage', dataIndex: 'batteryVoltage', key: 'batteryVoltage' },
                                    { title: 'Status', dataIndex: 'status', key: 'status' },
                                    { title: 'Last Updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                                ];

                                const fillColor = generatorBattery === 'N/A' ? grey : notliveData || generatorBattery < 0 ? grey :
                                    generatorBattery === 0 ? grey :
                                        generatorBattery < 10 ? red :
                                            generatorBattery < 12 ? '#F19D00' : green;

                                const key = `battery-${index}`;

                                return (
                                    <Popover
                                        content={
                                            <Table
                                                columns={columns}
                                                dataSource={tableData}
                                                pagination={false}
                                                size="small"
                                                bordered
                                            />
                                        }
                                        key={key}
                                        color="white"
                                        styles={{ body: { padding: 0 } }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {iconTheme === 0 ? (
                                                <div onClick={() => handleGeneratorClick(record?.name, items?.equipment_id, items.data[0]?.equipment_id, items.data[0]?.source_id)}
                                                    style={{ cursor: 'pointer', marginRight: 5 }}
                                                >
                                                    <ColorCircle
                                                        value={_.round(generatorBattery === 'N/A' ? -1 : generatorBattery, 0)}
                                                        color={fillColor}
                                                    />
                                                </div>
                                            ) : (
                                                <span>
                                                    <MainIconTemp3 fillColor={fillColor} />
                                                    <div onClick={() => handleGeneratorClick(record?.name, items?.equipment_id, items.data[0]?.equipment_id, items.data[0]?.source_id)}
                                                        style={{ cursor: 'pointer', marginLeft: 5 }}
                                                    >
                                                        {genName}
                                                    </div>
                                                </span>
                                            )}
                                        </div>
                                    </Popover>
                                );
                            })}
                    </Space>
                );
            },
        }
        ,
        {
            title: 'COMM',
            key: '8',
            dataIndex: 'comm',
            render: (text, record) => {
                const status = record?.location?.status === 1;

                const timeStamp = record?.location?.last_update_at;

                // Calculate time difference in minutes (both in UTC)

                // Determine the circle color based on the time difference
                const circleColor = status ? green : red;

                // Table columns for Tooltip
                const tableColumns = [
                    {
                        title: 'Last Updated ' + `(${selectedTimezoneAbb})`,
                        dataIndex: 'lastUpdated',
                        key: 'lastUpdated'
                    }
                ];
                const tableData = [
                    {
                        key: '1',
                        lastUpdated: timeStamp
                            ? selectedTimezone?.includes('UTC') ? dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss') : dayjs(timeStamp).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss')
                            : null
                    }
                ];

                return (
                    <Popover
                        styles={{ body: { padding: 0 } }}
                        content={
                            <Table
                                columns={tableColumns}
                                dataSource={tableData}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        }
                    >
                        <span>
                            <ColorCircle color={circleColor} value={-123} />
                        </span>
                    </Popover>
                );
            },
        }
        ,
        {
            title: 'TRAVEL MODE',
            key: '9',
            dataIndex: 'travel',
            render: () => {
                // const engineHours = record.generators[0]?.liveValues.find((item: any) => item.value_name === 'Engine Hours')?.value;
                return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
            },
        },
    ];

    // const defaultCheckedList = columns.map((item) => item.key);

    const defaultCheckedList = ['0', '1', '2', '3', '5', '7', '8'];

    const [checkedList, setCheckedList] = useState(defaultCheckedList);

    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
        key: key
    }));

    const newColumns = columns.map((item, key) => ({
        ...item,
        key: key,
        hidden: !checkedList.includes(item.key as string),
    }));


    const onSearch = (value: string) => {
        setSearchQuery(value);
    }

    return (
        <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Row gutter={[12, 12]} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Col span={'auto'} flex={1}>
                        <Input onChange={(e) => onSearch(e.target.value)} addonBefore={<SearchOutlined />} placeholder="Search by location/ Available/ Run/ Etc..." />
                    </Col>
                    <Col>
                        <Popover
                            content={<Row gutter={[12, 12]} style={{ width: '250px' }}>
                                <Col>
                                    <Checkbox.Group
                                        value={checkedList}
                                        options={options as CheckboxOptionType[]}
                                        onChange={(value) => {
                                            setCheckedList(value as string[]);
                                        }}
                                    />
                                </Col>
                            </Row>}
                            title="Title"
                            trigger="click"
                            open={open}
                            onOpenChange={handleOpenChange}
                        >
                            <Button
                                type="primary"
                                icon={<EyeInvisibleOutlined />}
                            //   onClick={() => enterLoading(1)}
                            >
                                Filter Columns
                            </Button>
                        </Popover>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Table
                    locale={{ emptyText: <Empty description="No Sites added for this customer." /> }}
                    scroll={{ x: isMobile ? 1920 : true, y: 400 }}
                    size="small"
                    pagination={false}
                    loading={
                        (permittedCustomersLoading ||
                            (customerSites.length === 0 && customerSitesLoading) ||
                            loadingDetailedChargerData ||
                            loadingGenDetails) &&
                        !loadingLength
                    }
                    key="table"
                    className={styles.customTable}
                    columns={newColumns}
                    dataSource={
                        searchQuery
                            ? generatorData.sort((a, b) => a.name.localeCompare(b.name))
                            : filteredData.sort((a, b) => a.name.localeCompare(b.name))
                    }
                />
            </Col>
        </Row>
    );
}

export default TableDashboard;
