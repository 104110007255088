import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './App.css';
import { ALARMS, CAMERAS, CHARGERS, DOCS, FUEL, GENERAL, INTRODUCTION, MAPS, POWER_GENERATION, REPORTS, SOLAR, STARLINK } from './utils/constants';
import { Col, ConfigProvider, Row, Spin, Typography } from 'antd';

import AppContent from './view/auth/AppContent';
import ErrorPage from './view/auth/ErrorPage';
import Dashboard from './view/dashboard/Dashboard';
import GlobalDashboard3 from './view/general/GlobalDashboard3';
import General from './view/general/General';
import Chargers from './view/charger/Chargers';
import IndividualCharger from './view/charger/IndividualCharger';
import Maps from './view/maps/Maps';
import Power from './view/power/Power';
import PowerIndividual from './view/power/PowerIndividual';
import Fuel from './view/fuel/Fuel';
import FuelIndividual from './view/fuel/FuelIndividual';
import Solar from './view/solar/Solar';
import Reports from './view/reports/Reports';
import Alarms from './view/alarms/Alarms';
import Camera2 from './view/cameras/Cameras2';
import Introduction from './view/introduction/introduction';
import StarLink from './view/starlink/Starlink';
import Docs from './view/docs/Docs';
import ErrorLoginPage from './view/error/errorLogin';
import ProtectedRoute from './ProtectedRoute';
import NoPermissionPage from './NoPermissionPage';
const FactoryView = lazy(() => import('./view/general/FactoryView'));

// Helper function for lowercase paths
const toLowerCasePath = (path: string) => path.toLowerCase();

// Loading fallback component
const LoadingFallback = (
  <Spin size="large" style={{ backgroundColor: 'transparent' }}>
    <Row justify="center">
      <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
        <Typography.Title level={2} type="secondary">
          Loading. Please wait!
        </Typography.Title>
      </Col>
    </Row>
  </Spin>
);

// Router configuration
const router = createBrowserRouter([
  {
    path: '/403',
    element: <NoPermissionPage />
  },
  {
    path: "/",
    element: <AppContent />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "FactoryDashboard",
        element: (<Suspense fallback={LoadingFallback}>
          <FactoryView />
        </Suspense>),
      },
      {
        path: ":customer",
        element: <GlobalDashboard3 />,
      },
      {
        path: ":customer/:site",
        element: <Dashboard />,
        children: [
          {
            index: true, // Default redirect
            element: <Navigate to={toLowerCasePath(GENERAL)} replace />,
          },
          {
            path: toLowerCasePath(GENERAL),
            element: <General />,
          },

          {
            path: toLowerCasePath(CHARGERS),
            element: (
              <ProtectedRoute route={CHARGERS}>
                <Chargers />
              </ProtectedRoute>
            ),
            children: [
              {
                path: ":chargerId",
                element: <IndividualCharger />,
              },
            ],
          },
          {
            path: toLowerCasePath(MAPS),
            element: (
              <ProtectedRoute route={MAPS}>
                <Maps />
              </ProtectedRoute>
            ),
          },
          {
            path: toLowerCasePath(POWER_GENERATION),
            element: (
              <ProtectedRoute route={POWER_GENERATION}>
                <Power />
              </ProtectedRoute>
            ),
            children: [
              {
                path: ":powerId",
                element: <PowerIndividual />,
              },
            ],
          },
          {
            path: toLowerCasePath(FUEL),
            element: (
              <ProtectedRoute route={FUEL}>
                <Fuel />
              </ProtectedRoute>
            ),
            children: [
              {
                path: ":fuelId",
                element: <FuelIndividual />,
              },
            ],
          },
          {
            path: toLowerCasePath(SOLAR),
            element: (
              <ProtectedRoute route={SOLAR}>
                <Solar />
              </ProtectedRoute>
            ),
          },
          {
            path: toLowerCasePath(REPORTS),
            element: (
              <ProtectedRoute route={REPORTS}>
                <Reports />
              </ProtectedRoute>
            ),
          },
          {
            path: toLowerCasePath(CAMERAS),
            element: (
              <ProtectedRoute route={CAMERAS}>
                <Camera2 />
              </ProtectedRoute>
            ),
          },
          {
            path: toLowerCasePath(ALARMS),
            element: (
              <ProtectedRoute route={ALARMS}>
                <Alarms />
              </ProtectedRoute>
            ),
          },
          // Uncomment if needed
          // {
          //   path: toLowerCasePath(INTRODUCTION),
          //   element: <Introduction />,
          // },
          {
            path: toLowerCasePath(STARLINK),
            element: (
              <ProtectedRoute route={STARLINK}>
                <StarLink />
              </ProtectedRoute>
            ),
          },
          {
            path: toLowerCasePath(DOCS),
            element: (
              <ProtectedRoute route={DOCS}>
                <Docs />
              </ProtectedRoute>
            ),
          },
          {
            path: "errorLogin",
            element: <ErrorLoginPage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "SF Pro Display",
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
